import { useEffect, useState } from "react";
import { getHoursCloseTicketsAuto } from "../../config";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { StatusTicket } from "../../utils/constants";

const useTickets = ({
    pageNumber,
    status,
    queueIds,
    withUnreadMessages,
    showAll,
    searchParam
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);
    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {                    
                    const { data } = await api.get("tickets", {
                        params: {
                            pageNumber,
                            status,
                            queueIds,
                            withUnreadMessages,
                            showAll,
                            searchParam
                        },
                    })
                    setTickets(data.tickets);                    
                    let hoursCloseTicketsAuto = getHoursCloseTicketsAuto();
                    if (status === StatusTicket.OPEN && hoursCloseTicketsAuto && hoursCloseTicketsAuto !== "" &&
                        hoursCloseTicketsAuto !== "0" && Number(hoursCloseTicketsAuto) > 0) {
                        let limitDate = new Date();
                        limitDate.setHours(limitDate.getHours() - Number(hoursCloseTicketsAuto));
                        data.tickets.forEach(ticket => {
                            if (ticket.status !== StatusTicket.CLOSED) {                                
                                let lastTicketInteractionDate = new Date(ticket.updatedAt);
                                if (lastTicketInteractionDate < limitDate)
                                    closeTicket(ticket)
                            }
                        })
                    }
                    setHasMore(data.hasMore);
                    setCount(data.count);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toastError(error);
                }
            };
            const closeTicket = async(ticket) => {
                await api.put(`/tickets/${ticket.id}`, {
                    status: StatusTicket.CLOSED,
                    userId: ticket.userId || null,
                })
            };
            fetchTickets();
        }, 500);
        return () => clearTimeout(delayDebounceFn)
    }, [        
        pageNumber,
        status,        
        queueIds,
        withUnreadMessages,
        showAll,
        searchParam
    ]);
    return { tickets, loading, hasMore, count };
};

export default useTickets;