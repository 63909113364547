import { InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import { useEffect, useState } from "react";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const Companies = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [hasMore, setHasMore] = useState(false);
    const fetchCompanies = async () => {
        setLoading(true);
        try {
            const { data } = await api.get("/companies/", {
                params: { searchParam, pageNumber },
            });            
            setCompanies(data.companies);
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }
    useEffect(() => {
        const deboundeFetchCompanies = setTimeout(fetchCompanies, 500);
        return () => clearInterval(deboundeFetchCompanies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);
    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };
    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };
    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) loadMore();
    };
    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("companies.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("companies.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t("companies.table.name")}</TableCell>
                            <TableCell>{i18n.t("companies.table.phone")}</TableCell>
                            <TableCell>{i18n.t("companies.table.email")}</TableCell>
                            <TableCell>{i18n.t("companies.table.status")}</TableCell>
                            <TableCell>{i18n.t("companies.table.dueDate")}</TableCell>
                            <TableCell>{i18n.t("companies.table.recurrence")}</TableCell>
                            <TableCell>{i18n.t("companies.table.plan")}</TableCell>
                            <TableCell>{i18n.t("companies.table.createdAt")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {companies.map((company) => (
                                <TableRow key={company.id}>
                                    <TableCell>{company.name}</TableCell>
                                    <TableCell>{company.phone}</TableCell>
                                    <TableCell>{company.email}</TableCell>
                                    <TableCell>{company.status}</TableCell>
                                    <TableCell>{company.dueDate}</TableCell>
                                    <TableCell>{company.recurrence}</TableCell>
                                    <TableCell>{company.pan}</TableCell>
                                    <TableCell>{company.createdAt}</TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={8} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Companies;