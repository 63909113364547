import { useEffect, useState } from "react";
import useAuth from "../useAuth.js";

const useCompany = () => {
    const { loading, user } = useAuth();
    const [companyId, setCompanyId] = useState(undefined);
    useEffect(() => {
        if (loading) return;
        const { companyId } = user;
        setCompanyId(companyId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    return companyId;
};

export default useCompany;