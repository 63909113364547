import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { format as fmt } from "date-fns";
import { messages } from "./languages";

i18n.use(LanguageDetector).init({
	debug: false,
	defaultNS: ["translations"],
	fallbackLng: "en",
	ns: ["translations"],
	resources: messages,
	interpolation: {
		escapeValue: false,
		format: (value, rawFormat, lng) => {
			const [format, ...aditionalValues] = rawFormat.split(",").map((v) => v.trim());
			if (value instanceof Date) return fmt(value, format);
			if (format === "currency") {
				const formats = Intl.NumberFormat(lng, {
					style: "currency",
					currency: aditionalValues[0]
				}).formatToParts(value)				
				const symbol = formats
					.filter(format => format.type === "currency")[0].value;
				const formatedValue = formats
					.filter(format => format.type !== "currency")
					.map(val => val.value)
					.join("");
				return `${symbol} ${formatedValue}`;
			}
			return value;
		}
	}
});

export { i18n };
